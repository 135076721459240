import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Link } from "react-router-dom"

function FAQ() {
    const { t } = useTranslation();

    const [q1Expanded, setQ1Expanded] = useState(false);
    const q1Clicked = () => {
        setQ1Expanded(!q1Expanded)
    }
    const [q2Expanded, setQ2Expanded] = useState(false);
    const q2Clicked = () => {
        setQ2Expanded(!q2Expanded)
    }
    const [q3Expanded, setQ3Expanded] = useState(false);
    const q3Clicked = () => {
        setQ3Expanded(!q3Expanded)
    }
    const [q4Expanded, setQ4Expanded] = useState(false);
    const q4Clicked = () => {
        setQ4Expanded(!q4Expanded)
    }
    const [q5Expanded, setQ5Expanded] = useState(false);
    const q5Clicked = () => {
        setQ5Expanded(!q5Expanded)
    }
    const [q6Expanded, setQ6Expanded] = useState(false);
    const q6Clicked = () => {
        setQ6Expanded(!q6Expanded)
    }
    const [q7Expanded, setQ7Expanded] = useState(false);
    const q7Clicked = () => {
        setQ7Expanded(!q7Expanded)
    }
    const [q8Expanded, setQ8Expanded] = useState(false);
    const q8Clicked = () => {
        setQ8Expanded(!q8Expanded)
    }
    const [q9Expanded, setQ9Expanded] = useState(false);
    const q9Clicked = () => {
        setQ9Expanded(!q9Expanded)
    }

    const handleIconClick = (e, toggleFunction) => {
        e.stopPropagation();
        toggleFunction();
    };

    return (
        <section id="faqs" className="faqs">
            <div className="faq-content">
                <h2>{t('faqs')}</h2>
                <div className="faq-display">
                    <div className="faq-question-container" onClick={q1Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion1")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q1Clicked)}
                            >
                                {q1Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q1Expanded && <p className="faq-response">{t("faqAnswer1")}</p>}

                    <div className="faq-question-container" onClick={q2Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion2")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q2Clicked)}
                            >
                                {q2Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q2Expanded && <>
                        <p className="faq-response">{t("faqAnswer2a")}</p>
                        <p className="faq-response">{t("faqAnswer2bi")} <Link to="https://www.merge.dev/integrations?api-category=HRIS">{t("faqAnswer2bii")}</Link></p>
                        <p className="faq-response">{t("faqAnswer2ci")} <Link to="https://www.merge.dev/integrations?api-category=ATS">{t("faqAnswer2cii")}</Link></p>
                    </>}

                    <div className="faq-question-container" onClick={q3Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion3")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q3Clicked)}
                            >
                                {q3Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q3Expanded && <p className="faq-response">{t("faqAnswer3")}</p>}

                    <div className="faq-question-container" onClick={q4Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion4")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q4Clicked)}
                            >
                                {q4Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q4Expanded && <>
                        <p className="faq-response">{t("faqAnswer4a")}</p>
                        <p className="faq-list-item">{t("faqAnswer4b")}</p>
                        <p className="faq-list-item">{t("faqAnswer4c")}</p>
                        <p className="faq-response">{t("faqAnswer4d")}</p>
                    </>}

                    <div className="faq-question-container" onClick={q5Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion5")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q5Clicked)}
                            >
                                {q5Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q5Expanded && <p className="faq-response">{t("faqAnswer5")}</p>}

                    <div className="faq-question-container" onClick={q6Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion6")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q6Clicked)}
                            >
                                {q6Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q6Expanded && <p className="faq-response">{t("faqAnswer6")}</p>}

                    <div className="faq-question-container" onClick={q7Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion7")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q7Clicked)}
                            >
                                {q7Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q7Expanded && <p className="faq-response">{t("faqAnswer7")}</p>}

                    <div className="faq-question-container" onClick={q8Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion8")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q8Clicked)}
                            >
                                {q8Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q8Expanded && <p className="faq-response">{t("faqAnswer8")}</p>}

                    <div className="faq-question-container" onClick={q9Clicked} style={{ cursor: 'pointer' }}>
                        <p className="faq-question">
                            <span>{t("faqQuestion9")}</span>
                            <IconButton
                                aria-label="Toggle answer"
                                onClick={(e) => handleIconClick(e, q9Clicked)}
                            >
                                {q9Expanded ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                        </p>
                    </div>
                    {q9Expanded && <p className="faq-response">{t("faqAnswer9")}</p>}
                </div>
            </div>
        </section>
    );
}

export default FAQ;
