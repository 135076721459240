import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Brain,
  Users,
  Lightbulb,
  ArrowRight
} from 'lucide-react';
import './KnowYour.css';

function KnowYour() {
  const { t } = useTranslation();
  const words = [t("knowYour1"), t("knowYour2"), t("knowYour3")];
  const [wordIndex, setWordIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsAnimating(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
}, [words.length]);

  return (
    <section className="know-your-section">
      <div className="container">
        <div className="grid-layout">
          <div className="content">
            <h2 className="title">
              {t("knowYour")} <span className={`dynamic-text ${isAnimating ? 'fade' : ''}`}>{words[wordIndex]}</span>
            </h2>
            <p className="description">
              {t("kypDescription")}
            </p>
            <a href="#demo" className="link">
              <span>{t("exploreMore")}</span>
              <ArrowRight className="ml-2 w-5 h-5" />
            </a>
          </div>

          <div className="features">
            {[
              {
                icon: <Brain className="w-6 h-6" />,
                title: t("advancedAuthenticationCapabilities"),
                description: t("advancedAuthenticationCapabilitiesDescription")
              },
              {
                icon: <Users className="w-6 h-6" />,
                title: t("streamlinedOnboarding"),
                description: t("streamlinedOnboardingDescription")
              },
              {
                icon: <Lightbulb className="w-6 h-6" />,
                title: t("highQualityInsights"),
                description: t("highQualityInsightsDescription")
              }
            ].map((feature, index) => (
              <div key={index} className="feature-card">
                <div className="feature-content">
                  <div className="icon-wrapper">
                    {feature.icon}
                  </div>
                  <div className="feature-text">
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-description">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default KnowYour;
