import React from "react";
import { useTranslation } from 'react-i18next';
import TeamGrid from "./TeamGrid";
import './AboutUs.css';


function AboutUs() {
    const { t } = useTranslation();

    // Team members must have name and position. Image, LinkedIn, Twitter, and Bluesky are optional.
    // Note: consider compressing any large images.

    // Justin and Paul are listed first as founders.
    const founders = [
        {
            name: "Justin Marciano",
            position: "CEO & Co-founder",
            type: "founder",
            image: "justin_compressed.png",
            linkedin: "https://linkedin.com/in/justin-marciano32/",
            twitter: "https://x.com/JustinMar32"
        },
        {
            name: "Paul Vann",
            position: "CTO & Co-founder",
            type: "founder",
            image: "paul_compressed.jpg",
            linkedin: "https://www.linkedin.com/in/paul-vann-b996b2120/"
        },
    ]

    // All remaining employees are currently listed alphabetically by first name.
    const teamMembers = [
        {
            name: "Aidan Niswender",
            position: "Social Media & Marketing Manager",
            type: "employee",
            image: "aidan.png",
            linkedin: "https://www.linkedin.com/in/aidan-niswender-b22154194/"
        },
        {
            name: "Annie Lin",
            position: "Software Engineer",
            type: "employee",
            image: "annie.png",
            linkedin: "https://www.linkedin.com/in/annieyhlin/"
        },
        {
            name: "Brendan Murphy",
            position: "AI Engineer",
            type: "employee",
            image: "brendan_compressed.jpg",
            linkedin: "https://www.linkedin.com/in/brendanphilipmurphy/"
        },
        // {
        //     name: "Divik Chotani",
        //     position: "Software Engineer",
        //     type: "employee",
        //     image: "divik.jpg",
        //     linkedin: "https://www.linkedin.com/in/divik-chotani/"
        // },
        {
            name: "Evan Baldonado",
            position: "Software Engineer",
            type: "employee",
            image: "https://evanbaldonado.com/images/evan_baldonado_at_russian_ridge.jpeg",
            linkedin: "https://linkedin.com/in/evanbaldonado/",
            bluesky: "https://bsky.app/profile/evanbaldonado.com"
        },
    ]

    // Advisors are listed last in their original order.
    const advisors = [
        {
            name: "Martin Roesch",
            position: "Advisor",
            type: "advisor",
            image: "martin.jpg",
            linkedin: "https://www.linkedin.com/in/maroesch/"
        },
        {
            name: "Allison Miller",
            position: "Advisor",
            type: "advisor",
            image: "allison.jpg",
            linkedin: "https://www.linkedin.com/in/allisonmiller/"
        },
        {
            name: "Jennifer Romeo",
            position: "Advisor",
            type: "advisor",
            image: "jennifer.jpg",
            linkedin: "https://www.linkedin.com/in/jenniferromeo/"
        }
    ];

    return (
        <section id="company" className="team-section">
            <div className="about-us-display" >
                <h2>{t("aboutUs")}</h2>
                <div className="about-us-grid">
                    <div className="about-us-item">
                        <h3>{t("ourMission")}</h3>
                        <p>{t("ourMissionDescription")}</p>
                    </div>
                    <div className="about-us-item">
                        <h3>{t("ourVision")}</h3>
                        <p>{t("ourVisionDescription")}</p>
                    </div>
                    <div className="about-us-item">
                        <h3>{t("ourValues")}</h3>
                        <ul>
                            <li>{t("ourValuesDescription1")}</li>
                            <li>{t("ourValuesDescription2")}</li>
                            <li>{t("ourValuesDescription3")}</li>
                            <li>{t("ourValuesDescription4")}</li>
                        </ul>
                    </div>
                </div>
                <br/><br/><br/>
                <h2>{t('meetOurTeam')}</h2>
                <TeamGrid members={founders}/>
                <TeamGrid members={teamMembers}/>
                <TeamGrid members={advisors}/>
            </div>
        </section>
    )
}

export default AboutUs;
