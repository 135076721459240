import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaYoutube, FaInstagram, FaFacebook } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  const location = useLocation();

  const handleNavClick = (event, sectionId) => {

    // Prevent the default behavior of the <a> tag. We keep the href on the <a> tag for accessibility + eslint warnings.
    event.preventDefault();

    // If not on the homepage, navigate to the homepage + anchor.
    if (location.pathname !== '/') {
      window.location.href = `/#${sectionId}`;
      return;
    }

    // If on the homepage, scroll to the anchor.
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">Validia</div>
        <div className="footer-links">
          <a href="/#product" onClick={(e) => handleNavClick(e, 'product')} style={{cursor: 'pointer'}}>{t('product')}</a>
          <a href="/#company" onClick={(e) => handleNavClick(e, 'company')} style={{cursor: 'pointer'}}>{t('company')}</a>
          <a href="/#testimonials" onClick={(e) => handleNavClick(e, 'testimonials')} style={{cursor: 'pointer'}}>{t('customers')}</a>
          <Link to="/blog">{t('blog')}</Link>
          <a href="/#contact" onClick={(e) => handleNavClick(e, 'contact')} style={{cursor: 'pointer'}}>{t('contact')}</a>
          <Link to="/privacy">{t('privacy')}</Link>
          <a href='https://calendly.com/d/cnf8-mcr-v2y/validia-demo-call-website'>{t('bookDemo')}</a>
        </div>
        <div className="social-links">
          <a href="https://www.linkedin.com/company/validia-ai" target="_blank" rel="noopener noreferrer" aria-label="Validia LinkedIn"><FaLinkedin /></a>
          <a href="https://x.com/validia_" target="_blank" rel="noopener noreferrer" aria-label="Validia Twitter"><FaTwitter /></a>
          <a href="https://instagram.com/validia.ai/" target="_blank" rel="noopener noreferrer" aria-label="Validia Instagram"><FaInstagram /></a>
          <a href="https://www.youtube.com/channel/UCC_Rdk6PslebcvCKn43NuNQ" target="_blank" rel="noopener noreferrer" aria-label="Validia Youtube"><FaYoutube /></a>
          <a href="https://www.facebook.com/profile.php?id=61563382212780" target="_blank" rel="noopener noreferrer" aria-label="Validia Facebook"><FaFacebook /></a>
        </div>
      </div>
      <div className="stats-logos">
          <div className="stats-logo-container">
              <img
                  src="c2pa_png.png"
                  alt="C2PA Logo"
                  className="stats-logo"
              />
          </div>
          <div className="stats-logo-container">
              <img
                  src="nvidia2.png"
                  alt="Nvidia Inception Program"
                  className="stats-logo"
              />
          </div>
          <div className="stats-logo-container">
              <img
                  src="soc.png"
                  alt="SOC Compliance"
                  className="stats-logo"
              />
          </div>
      </div>
      <div className="footer-bottom">
        {t('copyright', { year: new Date().getFullYear() })}
      </div>
    </footer>
  );
}

export default Footer;
