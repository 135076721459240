import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./FlashingStats.css";

function FlashingStats() {
  const { t } = useTranslation();
  const stats = [
    { bigNumber: "90%", description: t("stat1") },
    { bigNumber: "80%", description: t("stat2") },
    { bigNumber: "36%", description: t("stat3") },
    { bigNumber: "$40B", description: t("stat4") },
    { bigNumber: "26%", description: t("stat5") },
    { bigNumber: "52%", description: t("stat6") },
    { bigNumber: "<50%", description: t("stat7") },
    { bigNumber: "52%", description: t("stat8") },
  ];

  const SECONDS_PER_STAT = 6;

  const [currentStatIndex, setCurrentStatIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentStatIndex((prevIndex) => (prevIndex + 1) % stats.length);
        setFade(false);
      }, 500);
    }, SECONDS_PER_STAT * 1000);

    return () => clearInterval(timer);
  }, [stats.length]);

  return (
    <section className="container py-12 md:py-24 flashing-stats-container">
      <h2>{t('whyShouldYouProtect')}</h2>
      <div className="w-full max-w-4xl mx-auto flashing-stats">
        <div className="p-6 text-center">
          <div className={`fade-transition ${fade ? "fade-out" : "fade-in"}`}>
            <h3 className="text-6xl font-bold mb-4 text-black">
              {stats[currentStatIndex].bigNumber}
            </h3>
            <p className="text-xl">{stats[currentStatIndex].description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FlashingStats;
