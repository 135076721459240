import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "welcome": "Defending Workforces from AI-Driven Threats",
            "subtitle": "Validia is a platform for enabling safe digital interactions in the generative AI era.",
            "weSecure": "We secure meetings on the following platforms:",
            "product": "Product",
            "company": "Company",
            "aboutUs": "About Us",
            "customers": "Customers",
            "blog": "Blog",
            "caseStudies": "Case Studies",
            "contact": "Contact",
            "signin": "Sign in",
            "getDemo": "Get a demo",
            "metaDescription": "Validia protects against deepfakes and AI impersonation, securing enterprise digital identity in the age of adversarial AI.",
            "ogTitle": "Validia - Securing Enterprise Digital Identity",
            "ogDescription": "Protect against deepfakes and AI impersonation with Validia",
            "videoNotSupported": "Your browser does not support the video tag.",
            "comprehensiveProtection": "Comprehensive Protection",
            "proactiveDefense": "Proactive Defense",
            "proactiveDefenseDescription": "Prevent deepfake creation before it starts, safeguarding your digital identity.",
            "liveVerification": "Live Verification",
            "liveVerificationDescription": "Ensure authentic interactions in video conferences and digital communications.",
            "adaptiveSecurity": "Adaptive Security",
            "adaptiveSecurityDescription": "Stay protected against evolving AI threats with our continuously updated solutions.",
            "brandIntegrity": "Brand Integrity",
            "brandIntegrityDescription": "Maintain trust and credibility by securing your online presence against impersonation.",
            "unifiedPlatform": "A Unified Platform for Digital Identity Protection",
            "kyeTitle": "Know Your Employee",
            "kyeTagline": "Real-time identity verification for secure video calls",
            "kyeDescription": "Prevent unauthorized access and mitigate deepfake-enabled social engineering risks with our advanced AI-powered solution.",
            "kyeDetail1": "Continuous identity verification throughout video calls",
            "kyeDetail2": "AI-powered facial recognition and behavior analysis",
            "kyeDetail3": "Integration with major video conferencing platforms",
            "kyeDetail4": "Customizable alert systems for security teams",
            "kyeSlide1": "Add meeting in our Validia portal",
            "kyeSlide2": "Join your call!",
            "kyeSlide3": "Validia seamlessly joins the call",
            "kyeSlide4": "Live verification of users within 10 seconds",
            "jamTitle": "Jam",
            "jamTagline": "Pre-Attack Defense Against Deepfake Creation",
            "jamDescription": "Prevent the creation of Deepfake content before you even release your content",
            "jamDetail1": "Disrupts AI models used for deepfakes.",
            "jamDetail2": "Creates an invisible firewall around digital assets.",
            "jamDetail3": "Acts as a kill switch against unauthorized use of your digital identity",
            "jamDetail4": "Preserves your integrity as AI technology advances.",
            "jamDetail5": "Prevents the creation of convincing deepfakes using your data",
            "jamSlide1": "Invisible protective layer added to image",
            "jamSlide2": "A deepfake created with the original image",
            "jamSlide3": "A deepfake created with Validia's Jam",
            "stat1": "of online content could be synthetically generated by 2026",
            "stat2": "of employees are willing to act on AI-impersonated instructions",
            "stat3": "of employees are confident in identifying deepfake fraud",
            "stat4": "Projected deepfake losses by 2027, growing 32% annually",
            "stat5": "of C-Suite executives experienced deepfake incidents in past year",
            "stat6": "of C-Suite executives expect increased deepfake attacks on financial data",
            "stat7": "of C-Suite executives are confident in managing deepfake threats",
            "stat8": "of large enterprises expect increased deepfake attacks this year",
            "partnerTestimonials": "What Our Partners Say",
            "governmentTitle": "Government",
            "governmentTestimonial": "Validia offers a vital solution for protecting communications.",
            "governmentAuthor": "CISO, Foreign Embassy",
            "corporateSecurityTitle": "Corporate Security",
            "corporateSecurityTestimonial": "The need for a KYE/KYC solution like Validia is imperative for businesses in 2024 and beyond.",
            "corporateSecurityAuthor": "CRO, Data removal company & enterprises & UHNW",
            "internetMonitoringTitle": "Internet Monitoring",
            "internetMonitoringTestimonial": "The analysis from Validia helps us in our efforts to address misinformation-at-scale.",
            "internetMonitoringAuthor": "Head, MCA Deepfakes Analysis Unit",
            "meetOurTeam": "Meet Our Team",
            "getInTouch": "Get in Touch",
            "contactIntro": "Have questions? We'd love to hear from you.",
            "yourName": "Your Name",
            "yourEmail": "Your Email",
            "yourMessage": "Your Message",
            "sendMessage": "Send Message",
            "contactInformation": "Contact Information",
            "contactDescription": "We're here to help and answer any question you might have. We look forward to hearing from you.",
            "copyright": "© {{year}} Validia. All rights reserved.",
            "scheduleDemoTitle": "Experience a Demo as Your Digital Doppelgänger",
            "scheduleDemoSubtitle": "See the Power of Validia's KYE Verification by Meeting Your AI Twin",
            "scheduleDemoFormTitle": "Book Here!",
            "formName": "Full Name",
            "formEmail": "Business Email",
            "formCompany": "Company Name",
            "formImage": "Selfie Image",
            "formSubmit": "Submit Form",
            "testimonials": "Testimonials",
            "useCases": "Use Cases",
            "faqs": "FAQs",
            "faqQuestion1": "What platforms does Validia work with?",
            "faqQuestion2": "What enterprise products does Validia integrate with?",
            "faqQuestion3": "What is a Bioprint?",
            "faqQuestion4": "What about Privacy?",
            "faqQuestion5": "Do you offer on-premises solutions?",
            "faqQuestion6": "Does Validia store call recordings?",
            "faqQuestion7": "How flexible is Validia in terms of customization for specific organizational needs?",
            "faqQuestion8": "How does Validia protect against deepfakes?",
            "faqQuestion9": "Is Validia compatible with our existing security systems?",
            "faqAnswer1": "Validia works with Zoom, Microsoft Teams, Slack, Google Meet, and Webex!",
            "faqAnswer2a": "Calendars: Outlook and Google",
            "faqAnswer2bi": "HR Systems:",
            "faqAnswer2bii": "Over 50 platform integrations",
            "faqAnswer2ci": "ATS Systems:",
            "faqAnswer2cii": "Over 25 platform integrations",
            "faqAnswer3": "A Bioprint is Validia's unique facial and voice profile of an employee. It's created from a single image and short voice sample, and used for real-time identity verification during video and audio calls.",
            "faqAnswer4a": "Validia is SOC-II Type I compliant. The Type II audit is currently in progress. Our Bioprint technology offers flexible privacy options:",
            "faqAnswer4b": "1. At the organization's request, we can operate without storing any raw data from facial images or voice samples.",
            "faqAnswer4c": "2. Alternatively, we can store this data for observability purposes if the organization prefers.",
            "faqAnswer4d": "In either case, we only use irreversible embeddings for authentication. These embeddings cannot be converted back into original samples or used outside our platform, ensuring maximum security.",
            "faqAnswer5": "Yes, we have on-premises options in beta for clients with specific security needs.",
            "faqAnswer6": "No, Validia adheres to strict data privacy standards. The company does not store or use any call recordings.",
            "faqAnswer7": "Validia is highly customizable. You can set permissions at both organization and user levels, and turn features on or off based on your needs.",
            "faqAnswer8": "KYE verifies identities in real-time during calls. Jam corrupts the outputs of AI models, preventing them from accurately replicating protected digital assets.",
            "faqAnswer9": "Yes, Validia is designed to integrate with most existing enterprise security systems.",
            "talkToSomeoneReal": "Talk to Someone Real",
            "whyShouldYouProtect": "Using Your Eyes and Ears Has Become Insufficient",
            "aboutUsParagraph": "At Validia, we are a passionate and forward-thinking team dedicated to safeguarding digital integrity. As a startup at the forefront of deep fake prevention and protection solutions, we combine cutting-edge artificial intelligence with innovative security practices to help businesses, individuals, and platforms defend against the growing threat of AI-generated content manipulation. Our diverse team brings together expertise from AI research, cybersecurity, and digital forensics, all united by a shared commitment to building a safer and more trustworthy digital world. We don’t just fight deep fakes—we build the future of secure, authentic digital interactions.",
            "ourProducts": "Our Products",
            "privacy": "Privacy",
            "bookDemoNow": "Book a Demo Now",
            "bookDemo": "Demo",
            "newsletter": "Newsletter",
            "stayUpToDateOnDeepfakes": "Stay Up To Date on Deepfakes",
            "enterpriseIntegrations": "Enterprise Integrations",
            "strategicUseCases": "Strategic Use Cases",
            "strategicUseCasesSubtitle": "Discover how Validia addresses critical enterprise challenges in the AI era.",
            "ourMission": "Our Mission",
            "ourVision": "Our Vision",
            "ourValues": "Our Values",
            "ourMissionDescription": "To empower organizations with cutting-edge AI security solutions, ensuring trust and integrity in every digital interaction in the face of evolving AI-driven threats.",
            "ourVisionDescription": "A world where businesses can harness the power of AI without compromising on security, privacy, or authenticity.",
            "ourValuesDescription1": "Innovation in security",
            "ourValuesDescription2": "Integrity in all interactions",
            "ourValuesDescription3": "Proactive threat prevention",
            "ourValuesDescription4": "Continuous adaptation",
            "recruitmentFraudPrevention": "Recruitment Fraud Prevention",
            "vendorAndThirdPartySecurity": "Vendor and Third-Party Security",
            "mediaAndExecutiveProtection": "Media and Executive Protection",
            "fraudPrevention": "Fraud Prevention",
            "recruitmentFraudPreventionDescription": "Protect against AI-generated resumes, interview deepfakes, and fraudulent applications with our Know Your Applicant solution.",
            "vendorAndThirdPartySecurityDescription": "Secure interactions with external collaborators, such as vendors, contractors, and supply chain partners.",
            "mediaAndExecutiveProtectionDescription": "Prevent misuse of corporate media and high-profile executive likenesses in generative AI models.",
            "fraudPreventionDescription": "Block invoice tampering, man-in-the-middle attacks, and identity theft powered by AI.",
            "latestInsights": "Latest Insights",
            "readMoreOn": "Read more on",
            "validiasBlog": "Validia's blog",
            "advancedAuthenticationCapabilities": "Advanced Authentication Capabilities",
            "streamlinedOnboarding": "Streamlined Onboarding",
            "highQualityInsights": "High-Quality Insights",
            "advancedAuthenticationCapabilitiesDescription": "Leveraging in-house biometrics and advanced AI, Validia authenticates individuals with high accuracy.",
            "streamlinedOnboardingDescription": "For every use case, Validia offers a streamlined onboarding process to get you up and running as soon as possible.",
            "highQualityInsightsDescription": "With each authentication come unique insights, including where a potential attacker called from, if they've targeted you before, and more.",
            "kypDescription": "In a world of ever-improving artificial intelligence, impersonation attacks are imminent. Validia offers live identity authentication in the virtual workspace to ensure you know who you're talking to.",
            "knowYour": "Know Your",
            "knowYour1": "Employee",
            "knowYour2": "Applicant",
            "knowYour3": "Vendor",
            "exploreMore": "Explore More",
            "clickThroughDemo": "Click-Through Demo",
            "keyCapabilities": "Key Capabilities",
            "requestACustomDemo": "Request a Custom Demo",
            "secureMeetings": "Secure Meetings",
            "secureMeetingsDescription": "Experience proactive risk reduction, real-time security, and enhanced trust and compliance with Validia. Leverage Validia's advanced authentication technology to know and trust the people in your videos calls, whether they're employees, hiring candidates, or third-party vendors.",
            "secureMeetingsFeature1": "Seamlessly integrate with your calls",
            "secureMeetingsFeature2": "5–10 second authentications",
            "secureMeetingsFeature3": "Frictionless security",
            "readMore": "Read more",
        }
    },
    ja: {
        translation: {
            "welcome": "AI の脅威を出し抜きます。従業員を保護します",
            "subtitle": "リアルタイムの本人確認、ディープフェイクによる破壊、AI を利用した攻撃に対する包括的な保護により、企業を強化します",
            "weSecure": "私たちは次のプラットフォームで会議を確保します:",
            "product": "製品",
            "caseStudies": "ケーススタディ",
            "company": "会社",
            "aboutUs": "会社概要",
            "customers": "顧客",
            "blog": "ブログ",
            "contact": "お問い合わせ",
            "signin": "サインイン",
            "getDemo": "デモを見る",
            "metaDescription": "Validiaは、敵対的AIの時代にディープフェイクとAIなりすましから保護し、エンタープライズデジタルIDを確保します。",
            "ogTitle": "Validia - エンタープライズデジタルIDの保護",
            "ogDescription": "ValidiaでディープフェイクとAIなりすましから保護",
            "videoNotSupported": "お使いのブラウザは動画タグをサポートしていません。",
            "comprehensiveProtection": "包括的な保護",
            "proactiveDefense": "先制防御",
            "proactiveDefenseDescription": "ディープフェイクの作成を事前に防止し、デジタルIDを保護します。",
            "liveVerification": "リアルタイム検証",
            "liveVerificationDescription": "ビデオ会議やデジタルコミュニケーションでの本物の交流を確保します。",
            "adaptiveSecurity": "適応型セキュリティ",
            "adaptiveSecurityDescription": "常に更新されるソリューションで、進化するAIの脅威から保護します。",
            "brandIntegrity": "ブランド整合性",
            "brandIntegrityDescription": "オンラインでの存在を保護し、信頼性と信用を維持します。",
            "unifiedPlatform": "デジタルID保護のための統合プラットフォーム",
            "kyeTitle": "従業員確認",
            "kyeTagline": "安全なビデオ通話のためのリアルタイムID検証",
            "kyeDescription": "高度なAI搭載ソリューションで、不正アクセスを防止し、ディープフェイクを利用したソーシャルエンジニアリングのリスクを軽減します。",
            "kyeDetail1": "ビデオ通話中の継続的なID検証",
            "kyeDetail2": "AI搭載の顔認識と行動分析",
            "kyeDetail3": "主要なビデオ会議プラットフォームとの統合",
            "kyeDetail4": "セキュリティチーム向けのカスタマイズ可能なアラートシステム",
            "kyeSlide1": "Validiaポータルでミーティングを追加",
            "kyeSlide2": "通話に参加！",
            "kyeSlide3": "Validiaがシームレスに通話に参加",
            "kyeSlide4": "10秒以内にユーザーのリアルタイム検証",
            "jamTitle": "ジャム",
            "jamTagline": "ディープフェイク作成に対する事前防御",
            "jamDescription": "コンテンツを公開する前にディープフェイクコンテンツの作成を防止",
            "jamDetail1": "ディープフェイクに使用されるAIモデルを妨害します。",
            "jamDetail2": "デジタル資産周りに見えないファイアウォールを作成します。",
            "jamDetail3": "デジタルIDの不正使用に対するキルスイッチとして機能します。",
            "jamDetail4": "AI技術の進歩に伴い、あなたの整合性を保護します。",
            "jamDetail5": "あなたのデータを使用した説得力のあるディープフェイクの作成を防止します。",
            "jamSlide1": "画像に見えない保護層を追加",
            "jamSlide2": "オリジナル画像で作成されたディープフェイク",
            "jamSlide3": "ValidiaのJamで作成されたディープフェイク",
            "stat1": "2026年までにオンラインコンテンツの90%が合成的に生成される可能性があります",
            "stat2": "従業員の80%がAIによる偽装指示に従う意思があります",
            "stat3": "従業員の36%がそのような詐欺を識別する自信があります",
            "stat4": "2027年までのディープフェイクによる損失予測は年間32%成長で400億ドルです",
            "stat5": "過去 1 年間にディープフェイク事件を経験した経営幹部の割合",
            "stat6": "経営幹部の割合は、財務データに対するディープフェイク攻撃の増加を予想しています",
            "stat7": "の経営幹部がディープフェイクの脅威の管理に自信を持っています",
            "stat8": "の大企業の割合は、今年ディープフェイク攻撃が増加すると予想しています",
            "partnerTestimonials": "お客様の声",
            "governmentTitle": "政府",
            "governmentTestimonial": "Validiaは通信保護のための重要なソリューションを提供しています。",
            "governmentAuthor": "外国大使館 CISO",
            "corporateSecurityTitle": "企業セキュリティ",
            "corporateSecurityTestimonial": "ValidiaのようなKYE/KYCソリューションの必要性は、2024年以降のビジネスにとって不可欠です。",
            "corporateSecurityAuthor": "データ削除会社 & 企業 & UHNW CRO",
            "internetMonitoringTitle": "インターネットモニタリング",
            "internetMonitoringTestimonial": "Validiaの分析は、大規模な誤情報に対処する我々の取り組みに役立っています。",
            "internetMonitoringAuthor": "MCAディープフェイク分析ユニット責任者",
            "meetOurTeam": "チーム紹介",
            "getInTouch": "お問い合わせ",
            "contactIntro": "ご質問がありますか？お気軽にお問い合わせください。",
            "yourName": "お名前",
            "yourEmail": "メールアドレス",
            "yourMessage": "メッセージ",
            "sendMessage": "送信",
            "contactInformation": "連絡先情報",
            "contactDescription": "お客様のご質問にお答えし、サポートさせていただきます。お問い合わせをお待ちしております。",
            "copyright": "© {{year}} Validia. All rights reserved.",
            "scheduleDemoTitle": "デジタルドッペルゲンガーとしてデモを体験",
            "scheduleDemoSubtitle": "あなたのAIツインに会って、ValidiaのKYE認証の力を体験してください",
            "scheduleDemoFormTitle": "ここで予約！",
            "formName": "氏名",
            "formEmail": "ビジネスメールアドレス",
            "formCompany": "会社名",
            "formImage": "自撮り画像をアップロード",
            "formSubmit": "デモをスケジュール",
            "testimonials": "証言",
            "useCases": "使用例",
            "faqs": "よくある質問",
            "faqQuestion1": "Validiaはどのプラットフォームと連携していますか？",
            "faqQuestion2": "Validiaはどの企業向け製品と統合していますか？",
            "faqQuestion3": "バイオプリントとは何ですか？",
            "faqQuestion4": "プライバシーについてはどうですか？",
            "faqQuestion5": "オンプレミスのソリューションは提供していますか？",
            "faqQuestion6": "Validiaは通話録音を保存していますか？",
            "faqQuestion7": "特定の組織のニーズに対するカスタマイズの柔軟性はどれくらいですか？",
            "faqQuestion8": "Validiaはディープフェイクにどのように対処していますか？",
            "faqQuestion9": "Validiaは既存のセキュリティシステムと互換性がありますか？",
            "faqAnswer1": "ValidiaはZoom、Microsoft Teams、Slack、Google Meet、Webexと連携します！",
            "faqAnswer2a": "カレンダー: OutlookとGoogle",
            "faqAnswer2bi": "HRシステム:",
            "faqAnswer2bii": "50以上のプラットフォーム統合",
            "faqAnswer2ci": "ATSシステム:",
            "faqAnswer2cii": "25以上のプラットフォーム統合",
            "faqAnswer3": "Bioprintは、Validia独自の従業員の顔と声のプロフィールです。1枚の画像と短い音声サンプルから作成され、ビデオ通話と音声通話中のリアルタイムな本人確認に使用されます。",
            "faqAnswer4a": "ValidiaはSOC-IIタイプI準拠です。タイプII監査は現在進行中です。私たちのBioprint技術は柔軟なプライバシーオプションを提供します:",
            "faqAnswer4b": "1. 組織のリクエストに応じて、顔画像や音声サンプルの生データを保存せずに運用できます。",
            "faqAnswer4c": "2. 代わりに、組織が希望する場合は、このデータを監視目的で保存できます。",
            "faqAnswer4d": "いずれの場合でも、認証には不可逆的な埋め込みのみを使用します。これらの埋め込みは元のサンプルに戻すことはできず、私たちのプラットフォーム外で使用することもできません。これにより、最大限のセキュリティが確保されます。",
            "faqAnswer5": "はい、特定のセキュリティニーズを持つクライアントのために、ベータ版のオンプレミスオプションがあります。",
            "faqAnswer6": "いいえ、Validiaは厳格なデータプライバシー基準に準拠しています。会社は通話の録音を保存したり使用したりすることはありません。",
            "faqAnswer7": "Validiaは非常にカスタマイズ可能です。組織およびユーザーのレベルで権限を設定でき、必要に応じて機能をオンまたはオフにすることができます。",
            "faqAnswer8": "KYEは通話中にリアルタイムで本人確認を行います。JamはAIモデルの出力を破損させ、保護されたデジタル資産を正確に再現できないようにします。",
            "faqAnswer9": "はい、Validiaはほとんどの既存の企業セキュリティシステムと統合するように設計されています。",
            "talkToSomeoneReal": "本当の誰かと話す",
            "whyShouldYouProtect": "なぜ自分を守る必要があるのですか?",
            "aboutUsParagraph": "Validia は、デジタル整合性の保護に専念する情熱的で先進的なチームです。ディープフェイク防止および保護ソリューションの最前線に立つスタートアップとして、当社は最先端の人工知能と革新的なセキュリティ実践を組み合わせて、企業、個人、プラットフォームが AI によって生成されたコンテンツ操作の増大する脅威から防御できるよう支援します。当社の多様なチームは、AI 研究、サイバーセキュリティ、デジタル フォレンジックの専門知識を結集し、より安全で信頼できるデジタル世界を構築するという共通の取り組みによって団結しています。私たちはディープフェイクと戦うだけではなく、安全で本物のデジタル インタラクションの未来を構築します。",
            "ourProducts": "当社の製品",
            "privacy": "プライバシー",
            "bookDemoNow": "今すぐデモを予約する",
            "bookDemo": "デモを予約する",
            "newsletter": "ニュースレター",
            "stayUpToDateOnDeepfakes": "ディープフェイクに関する最新情報を入手する",
            "enterpriseIntegrations": "企業統合",
            "strategicUseCases": "戦略的ユースケース",
            "strategicUseCasesSubtitle": "ValidiaがAI時代における企業の重要な課題にどのように対応しているかを発見してください。",
            "ourMission": "私たちの使命",
            "ourVision": "私たちのビジョン",
            "ourValues": "私たちの価値観",
            "ourMissionDescription": "進化するAI主導の脅威に直面して、信頼と誠実を保ちつつ、最先端のAIセキュリティソリューションで組織を支援すること。",
            "ourVisionDescription": "セキュリティ、プライバシー、または信頼性を損なうことなく、企業がAIの力を活用できる世界。",
            "ourValuesDescription1": "セキュリティにおける革新",
            "ourValuesDescription2": "すべてのやり取りにおける誠実",
            "ourValuesDescription3": "積極的な脅威予防",
            "ourValuesDescription4": "継続的な適応",
            "recruitmentFraudPrevention": "採用詐欺防止",
            "vendorAndThirdPartySecurity": "ベンダーおよびサードパーティセキュリティ",
            "mediaAndExecutiveProtection": "メディアおよびエグゼクティブ保護",
            "fraudPrevention": "詐欺防止",
            "recruitmentFraudPreventionDescription": "AI生成の履歴書、面接のディープフェイク、そして詐欺的な応募を私たちの応募者確認ソリューションで防ぎます。",
            "vendorAndThirdPartySecurityDescription": "ベンダー、契約者、サプライチェーンパートナーなどの外部の協力者とのやり取りを安全に保護します。",
            "mediaAndExecutiveProtectionDescription": "生成AIモデルでの企業メディアや高プロファイルな経営者の肖像の不正使用を防ぎます。",
            "fraudPreventionDescription": "AIによる請求書改ざん、中間者攻撃、身元盗難をブロックします。",
            "latestInsights": "最新の洞察",
            "readMoreOn": "さらに読む",
            "validiasBlog": "バリディアのブログ",
            "advancedAuthenticationCapabilities": "高度な認証機能",
            "streamlinedOnboarding": "簡素化されたオンボーディング",
            "highQualityInsights": "高品質なインサイト",
            "advancedAuthenticationCapabilitiesDescription": "社内の生体認証と高度なAIを活用し、Validiaは高精度で個人を認証します。",
            "streamlinedOnboardingDescription": "すべてのユースケースにおいて、Validiaは迅速に立ち上げができる簡素化されたオンボーディングプロセスを提供します。",
            "highQualityInsightsDescription": "認証ごとにユニークなインサイトが提供され、攻撃者がどこから呼びかけてきたのか、以前にターゲットにされたことがあるか、などがわかります。",
            "kypDescription": "日々進化する人工知能の世界において、なりすまし攻撃は避けられません。Validiaは仮想ワークスペースでリアルタイムの本人確認を提供し、誰と話しているのかを確実に知ることができます。",
            "knowYour": "知っておくべき",
            "knowYour1": "従業員",
            "knowYour2": "応募者",
            "knowYour3": "ベンダー",
            "exploreMore": "もっと探索する",
            "clickThroughDemo": "クリックスルーデモ",
            "keyCapabilities": "主な機能",
            "requestACustomDemo": "カスタムデモのリクエスト",
            "secureMeetings": "セキュアミーティング",
            "secureMeetingsDescription": "Validiaでリスクの先取り軽減、リアルタイムのセキュリティ、そして信頼とコンプライアンスの強化を体験してください。Validiaの高度な認証技術を活用し、従業員、採用候補者、または第三者のベンダーなど、ビデオ通話に参加する人々を把握し、信頼できる環境を提供します。",
            "secureMeetingsFeature1": "通話にシームレスに統合",
            "secureMeetingsFeature2": "5〜10秒の認証",
            "secureMeetingsFeature3": "摩擦のないセキュリティ",
            "readMore": "続きを読む",
        }
    },
    es: {
        translation: {
            "welcome": "Supere las amenazas de IA. Asegure su fuerza laboral.",
            "subtitle": "Fortalezca a su empresa con verificación de identidad en tiempo real, disrupción de deepfake y protección integral contra ataques impulsados ​​por IA",
            "weSecure": "Aseguramos reuniones en las siguientes plataformas:",
            "product": "Producto",
            "company": "Empresa",
            "aboutUs": "Sobre Nosotros",
            "customers": "Clientes",
            "blog": "Blog",
            "caseStudies": "Estudios de Caso",
            "contact": "Contacto",
            "signin": "Iniciar sesión",
            "getDemo": "Obtener una demo",
            "metaDescription": "Validia protege contra deepfakes e imposición de IA, asegurando la identidad digital empresarial en la era de la IA adversaria.",
            "ogTitle": "Validia - Protegiendo la Identidad Digital Empresarial",
            "ogDescription": "Protéjase contra deepfakes e imposición de IA con Validia",
            "videoNotSupported": "Su navegador no soporta la etiqueta de video.",
            "comprehensiveProtection": "Protección Integral",
            "proactiveDefense": "Defensa Proactiva",
            "proactiveDefenseDescription": "Evite la creación de deepfakes antes de que comiencen, salvaguardando su identidad digital.",
            "liveVerification": "Verificación en Vivo",
            "liveVerificationDescription": "Asegure interacciones auténticas en videoconferencias y comunicaciones digitales.",
            "adaptiveSecurity": "Seguridad Adaptativa",
            "adaptiveSecurityDescription": "Manténgase protegido contra amenazas de IA en evolución con nuestras soluciones continuamente actualizadas.",
            "brandIntegrity": "Integridad de Marca",
            "brandIntegrityDescription": "Mantenga la confianza y credibilidad asegurando su presencia en línea contra la suplantación.",
            "unifiedPlatform": "Una Plataforma Unificada para la Protección de Identidad Digital",
            "kyeTitle": "Conozca a Su Empleado",
            "kyeTagline": "Verificación de identidad en tiempo real para llamadas de video seguras",
            "kyeDescription": "Evite el acceso no autorizado y mitigue los riesgos de ingeniería social habilitados por deepfakes con nuestra solución avanzada impulsada por IA.",
            "kyeDetail1": "Verificación de identidad continua durante las videollamadas",
            "kyeDetail2": "Reconocimiento facial y análisis de comportamiento impulsado por IA",
            "kyeDetail3": "Integración con las principales plataformas de videoconferencia",
            "kyeDetail4": "Sistemas de alerta personalizables para equipos de seguridad",
            "kyeSlide1": "Agregue reuniones en nuestro portal Validia",
            "kyeSlide2": "¡Únase a su llamada!",
            "kyeSlide3": "Validia se une a la llamada sin problemas",
            "kyeSlide4": "Verificación en vivo de usuarios en 10 segundos",
            "jamTitle": "Jam",
            "jamTagline": "Defensa Pre-Ataque Contra la Creación de Deepfakes",
            "jamDescription": "Evite la creación de contenido Deepfake antes incluso de que libere su contenido",
            "jamDetail1": "Interrumpe los modelos de IA utilizados para deepfakes.",
            "jamDetail2": "Crea un firewall invisible alrededor de los activos digitales.",
            "jamDetail3": "Actúa como un interruptor de emergencia contra el uso no autorizado de su identidad digital",
            "jamDetail4": "Preserva su integridad a medida que avanza la tecnología de IA.",
            "jamDetail5": "Evita la creación de deepfakes convincentes utilizando sus datos",
            "jamSlide1": "Capa Protectora Invisible Añadida a la Imagen",
            "jamSlide2": "Un deepfake creado con la imagen original",
            "jamSlide3": "Un deepfake creado con Jam de Validia",
            "stat1": "del contenido en línea podría ser generado sintéticamente para 2026",
            "stat2": "de los empleados están dispuestos a actuar según instrucciones suplantadas por IA",
            "stat3": "de los empleados confían en identificar tal fraude",
            "stat4": "Pérdidas proyectadas por deepfakes para 2027, creciendo un 32% anualmente",
            "stat5": "de los ejecutivos de C-Suite experimentaron incidentes deepfake el año pasado",
            "stat6": "de los ejecutivos de C-Suite esperan un aumento de los ataques deepfake a los datos financieros",
            "stat7": "de los ejecutivos de C-Suite confían en la gestión de amenazas deepfake",
            "stat8": "de las grandes empresas esperan un aumento de los ataques deepfake este año",
            "partnerTestimonials": "Lo que Dicen Nuestros Socios",
            "governmentTitle": "Gobierno",
            "governmentTestimonial": "Validia ofrece una solución vital para proteger las comunicaciones.",
            "governmentAuthor": "CISO, Embajada Extranjera",
            "corporateSecurityTitle": "Seguridad Corporativa",
            "corporateSecurityTestimonial": "La necesidad de una solución KYE/KYC como Validia es imperativa para las empresas en 2024 y más allá.",
            "corporateSecurityAuthor": "CRO, Empresa de eliminación de datos y empresas y UHNW",
            "internetMonitoringTitle": "Monitoreo de Internet",
            "internetMonitoringTestimonial": "El análisis de Validia nos ayuda en nuestros esfuerzos para abordar la desinformación a gran escala.",
            "internetMonitoringAuthor": "Jefe, Unidad de Análisis de Deepfakes MCA",
            "meetOurTeam": "Conozca a Nuestro Equipo",
            "getInTouch": "Póngase en Contacto",
            "contactIntro": "¿Tiene preguntas? Nos encantaría saber de usted.",
            "yourName": "Su Nombre",
            "yourEmail": "Su Correo Electrónico",
            "yourMessage": "Su Mensaje",
            "sendMessage": "Enviar Mensaje",
            "contactInformation": "Información de Contacto",
            "contactDescription": "Estamos aquí para ayudar y responder cualquier pregunta que pueda tener. Esperamos saber de usted.",
            "copyright": "© {{year}} Validia. Todos los derechos reservados.",
            "scheduleDemoTitle": "Experimente una demostración como su Doppelgänger digital",
            "scheduleDemoSubtitle": "Vea el poder de la verificación KYE de Validia conociendo a su gemelo AI",
            "scheduleDemoFormTitle": "¡Reserve aquí!",
            "formName": "Nombre completo",
            "formEmail": "Correo electrónico de trabajo",
            "formCompany": "Nombre de la empresa",
            "formImage": "Subir imagen de selfie",
            "formSubmit": "Programar demostración",
            "testimonials": "Testimonios",
            "useCases": "Casos de Uso",
            "faqs": "Preguntas Frecuentes",
            "faqQuestion1": "¿Con qué plataformas trabaja Validia?",
            "faqQuestion2": "¿Con qué productos empresariales se integra Validia?",
            "faqQuestion3": "¿Qué es un Bioprint?",
            "faqQuestion4": "¿Qué pasa con la privacidad?",
            "faqQuestion5": "¿Ofrecen soluciones locales?",
            "faqQuestion6": "¿Validia almacena grabaciones de llamadas?",
            "faqQuestion7": "¿Qué tan flexible es Validia en términos de personalización para necesidades organizacionales específicas?",
            "faqQuestion8": "¿Cómo protege Validia contra los deepfakes?",
            "faqQuestion9": "¿Es Validia compatible con nuestros sistemas de seguridad existentes?",
            "faqAnswer1": "¡Validia funciona con Zoom, Microsoft Teams, Slack, Google Meet y Webex!",
            "faqAnswer2a": "Calendarios: Outlook y Google",
            "faqAnswer2bi": "Sistemas de Recursos Humanos:",
            "faqAnswer2bii": "Más de 50 integraciones de plataformas",
            "faqAnswer2ci": "Sistemas ATS:",
            "faqAnswer2cii": "Más de 25 integraciones de plataformas",
            "faqAnswer3": "Bioprint es el perfil único de cara y voz de un empleado en Validia. Se crea a partir de una sola imagen y una muestra de voz corta, y se utiliza para la verificación de identidad en tiempo real durante llamadas de video y audio.",
            "faqAnswer4a": "Validia cumple con la norma SOC-II Tipo I. La auditoría Tipo II está en progreso. Nuestra tecnología Bioprint ofrece opciones flexibles de privacidad:",
            "faqAnswer4b": "1. A petición de la organización, podemos operar sin almacenar datos sin procesar de imágenes faciales o muestras de voz.",
            "faqAnswer4c": "2. Alternativamente, podemos almacenar estos datos para fines de observabilidad si la organización lo prefiere.",
            "faqAnswer4d": "En cualquier caso, solo utilizamos incrustaciones irreversibles para la autenticación. Estas incrustaciones no se pueden convertir de nuevo en las muestras originales ni se pueden usar fuera de nuestra plataforma, lo que garantiza la máxima seguridad.",
            "faqAnswer5": "Sí, tenemos opciones locales en beta para clientes con necesidades de seguridad específicas.",
            "faqAnswer6": "No, Validia se adhiere a estrictos estándares de privacidad de datos. La empresa no almacena ni utiliza grabaciones de llamadas.",
            "faqAnswer7": "Validia es altamente personalizable. Puedes establecer permisos tanto a nivel organizacional como de usuario, y activar o desactivar funciones según tus necesidades.",
            "faqAnswer8": "KYE verifica identidades en tiempo real durante las llamadas. Jam corrompe las salidas de los modelos de IA, evitando que reproduzcan con precisión los activos digitales protegidos.",
            "faqAnswer9": "Sí, Validia está diseñada para integrarse con la mayoría de los sistemas de seguridad empresarial existentes.",
            "talkToSomeoneReal": "Hablar con Alguien Real",
            "whyShouldYouProtect": "¿Por qué deberías protegerte?",
            "aboutUsParagraph": "En Validia, somos un equipo apasionado y con visión de futuro dedicado a salvaguardar la integridad digital. Como startup a la vanguardia de las soluciones de protección y prevención de deepfake, combinamos inteligencia artificial de vanguardia con prácticas de seguridad innovadoras para ayudar a empresas, individuos y plataformas a defenderse contra la creciente amenaza de la manipulación de contenido generado por IA. Nuestro equipo diverso reúne experiencia en investigación de inteligencia artificial, ciberseguridad y análisis forense digital, todos unidos por un compromiso compartido de construir un mundo digital más seguro y confiable. No solo luchamos contra las falsificaciones profundas: construimos el futuro de interacciones digitales auténticas y seguras.",
            "ourProducts": "Nuestros Productos",
            "privacy": "Privacidad",
            "bookDemoNow": "Reserve una demostración ahora",
            "bookDemo": "Reserve una demostración",
            "newsletter": "Hoja informativa",
            "stayUpToDateOnDeepfakes": "Manténgase actualizado sobre deepfakes",
            "enterpriseIntegrations": "Integraciones Empresariales",
            "strategicUseCases": " Casos de uso estratégicos",
            "strategicUseCasesSubtitle": "Descubre cómo Validia aborda los desafíos críticos de las empresas en la era de la IA.",
            "ourMission": "Nuestra Misión",
            "ourVision": "Nuestra Visión",
            "ourValues": "Nuestros Valores",
            "ourMissionDescription": "Empoderar a las organizaciones con soluciones de seguridad de IA de vanguardia, asegurando la confianza y la integridad en cada interacción digital frente a amenazas impulsadas por IA en evolución.",
            "ourVisionDescription": "Un mundo donde las empresas puedan aprovechar el poder de la IA sin comprometer la seguridad, la privacidad ni la autenticidad.",
            "ourValuesDescription1": "Innovación en seguridad",
            "ourValuesDescription2": "Integridad en todas las interacciones",
            "ourValuesDescription3": "Prevención proactiva de amenazas",
            "ourValuesDescription4": "Adaptación continua",
            "recruitmentFraudPrevention": "Prevención de Fraude en Reclutamiento",
            "vendorAndThirdPartySecurity": "Seguridad de Proveedores y Terceros",
            "mediaAndExecutiveProtection": "Protección de Medios y Ejecutivos",
            "fraudPrevention": "Prevención de Fraude",
            "recruitmentFraudPreventionDescription": "Protege contra currículos generados por IA, deepfakes en entrevistas y aplicaciones fraudulentas con nuestra solución de Conozca a Su Solicitante.",
            "vendorAndThirdPartySecurityDescription": "Asegure las interacciones con colaboradores externos, como proveedores, contratistas y socios de la cadena de suministro.",
            "mediaAndExecutiveProtectionDescription": "Evite el uso indebido de medios corporativos y la imagen de ejecutivos de alto perfil en modelos generativos de IA.",
            "fraudPreventionDescription": "Bloquee la manipulación de facturas, ataques de intermediarios y el robo de identidad impulsado por IA.",
            "latestInsights": "Últimas perspectivas",
            "readMoreOn": "Leer más en",
            "validiasBlog": "Blog de Validia",
            "advancedAuthenticationCapabilities": "Capacidades de Autenticación Avanzada",
            "streamlinedOnboarding": "Onboarding Simplificado",
            "highQualityInsights": "Información de Alta Calidad",
            "advancedAuthenticationCapabilitiesDescription": "Aprovechando biometría interna y IA avanzada, Validia autentica a individuos con alta precisión.",
            "streamlinedOnboardingDescription": "Para cada caso de uso, Validia ofrece un proceso de onboarding simplificado para ponerlo en marcha lo antes posible.",
            "highQualityInsightsDescription": "Con cada autenticación vienen perspectivas únicas, incluyendo desde dónde llamó un posible atacante, si te ha atacado antes, y más.",
            "kypDescription": "En un mundo de inteligencia artificial en constante mejora, los ataques de suplantación son inminentes. Validia ofrece autenticación en vivo de identidad en el espacio virtual para asegurar que sepas con quién estás hablando.",
            "knowYour": "Conocer a Tu",
            "knowYour1": "Empleade",
            "knowYour2": "Candidate",
            "knowYour3": "Proveedore",
            "exploreMore": "Explorar más",
            "clickThroughDemo": "Demo de clics",
            "keyCapabilities": "Principales capacidades",
            "requestACustomDemo": "Solicitar una demo personalizada",
            "secureMeetings": "Reuniones seguras",
            "secureMeetingsDescription": "Experimente una reducción proactiva de riesgos, seguridad en tiempo real y una mayor confianza y cumplimiento con Validia. Aproveche la avanzada tecnología de autenticación de Validia para conocer y confiar en las personas en sus videollamadas, ya sean empleados, candidatos a empleo o proveedores externos.",
            "secureMeetingsFeature1": "Integración perfecta con sus llamadas",
            "secureMeetingsFeature2": "Autenticaciones de 5-10 segundos",
            "secureMeetingsFeature3": "Seguridad sin fricciones",
            "readMore": "Leer más",
        }
    },
    zh: {
        translation: {
            "welcome": "智胜人工智能威胁。确保您的劳动力安全。",
            "subtitle": "为您的企业提供实时身份验证、深度伪造破坏和针对人工智能攻击的全面防护",
            "weSecure": "我们在以下平台上确保会议：",
            "product": "产品",
            "company": "公司",
            "aboutUs": "关于公司",
            "customers": "客户",
            "blog": "博客",
            "caseStudies": "案例研究",
            "contact": "联系我们",
            "signin": "登录",
            "getDemo": "获取演示",
            "metaDescription": "Validia保护企业免受deepfake和AI冒充的威胁，在对抗性人工智能时代保护企业数字身份。",
            "ogTitle": "Validia - 保护企业数字身份",
            "ogDescription": "使用Validia防御Deepfake和AI冒充",
            "videoNotSupported": "您的浏览器不支持视频标签。",
            "comprehensiveProtection": "全面保护",
            "proactiveDefense": "主动防御",
            "proactiveDefenseDescription": "在deepfake创建之前就阻止它，保护您的数字身份。",
            "liveVerification": "实时验证",
            "liveVerificationDescription": "确保视频会议和数字通信中的真实互动。",
            "adaptiveSecurity": "自适应安全",
            "adaptiveSecurityDescription": "通过我们持续更新的解决方案，保护您免受不断演变的AI威胁。",
            "brandIntegrity": "品牌完整性",
            "brandIntegrityDescription": "通过保护您的在线存在免受冒充，保持信任和可信度。",
            "unifiedPlatform": "数字身份保护统一平台",
            "kyeTitle": "了解您的员工",
            "kyeTagline": "安全视频通话的实时身份验证",
            "kyeDescription": "通过我们先进的AI驱动解决方案，防止未经授权的访问并降低由deepfake引起的社会工程风险。",
            "kyeDetail1": "视频通话全程持续身份验证",
            "kyeDetail2": "AI驱动的面部识别和行为分析",
            "kyeDetail3": "与主要视频会议平台集成",
            "kyeDetail4": "为安全团队提供可定制的警报系统",
            "kyeSlide1": "在我们的Validia门户中添加会议",
            "kyeSlide2": "加入您的通话！",
            "kyeSlide3": "Validia无缝加入通话",
            "kyeSlide4": "10秒内完成用户实时验证",
            "jamTitle": "Jam",
            "jamTagline": "Deepfake创建的预防性防御",
            "jamDescription": "在您发布内容之前就防止Deepfake内容的创建",
            "jamDetail1": "破坏用于deepfake的AI模型。",
            "jamDetail2": "在数字资产周围创建一个无形的防火墙。",
            "jamDetail3": "作为防止未经授权使用您的数字身份的终止开关",
            "jamDetail4": "随着AI技术的进步保护您的完整性。",
            "jamDetail5": "防止使用您的数据创建令人信服的deepfakes",
            "jamSlide1": "为图像添加无形保护层",
            "jamSlide2": "使用原始图像创建的deepfake",
            "jamSlide3": "使用Validia的Jam创建的deepfake",
            "stat1": "到2026年，在线内容可能由合成生成",
            "stat2": "的员工愿意按照AI冒充的指示行事",
            "stat3": "的员工有信心识别此类欺诈",
            "stat4": "到2027年，deepfake造成的损失预计每年增长32%",
            "stat5": "的高管在过去一年中经历过深度造假事件",
            "stat6": "的高管预计金融数据的深度造假攻击将会增加",
            "stat7": "的首席高管对管理深度伪造威胁充满信心",
            "stat8": "的大型企业预计今年深度伪造攻击将会增加",
            "partnerTestimonials": "客户评价",
            "governmentTitle": "政府",
            "governmentTestimonial": "Validia为保护通信提供了至关重要的解决方案。",
            "governmentAuthor": "外国大使馆首席信息安全官",
            "corporateSecurityTitle": "企业安全",
            "corporateSecurityTestimonial": "像Validia这样的KYE/KYC解决方案对2024年及以后的企业来说是必不可少的。",
            "corporateSecurityAuthor": "数据删除公司、企业和超高净值客户首席风险官",
            "internetMonitoringTitle": "互联网监控",
            "internetMonitoringTestimonial": "Validia的分析帮助我们努力解决大规模虚假信息问题。",
            "internetMonitoringAuthor": "MCA Deepfakes分析部门负责人",
            "meetOurTeam": "认识我们的团队",
            "getInTouch": "联系我们",
            "contactIntro": "有问题吗？我们很乐意听取您的意见。",
            "yourName": "您的姓名",
            "yourEmail": "您的电子邮箱",
            "yourMessage": "您的留言",
            "sendMessage": "发送消息",
            "contactInformation": "联系信息",
            "contactDescription": "我们在这里为您提供帮助并回答您可能有的任何问题。我们期待收到您的来信。",
            "copyright": "© {{year}} Validia。保留所有权利。",
            "scheduleDemoTitle": "体验成为您的数字分身",
            "scheduleDemoSubtitle": "通过与您的AI分身会面，体验Validia的KYE验证力量",
            "scheduleDemoFormTitle": "在此预约！",
            "formName": "全名",
            "formEmail": "商务邮箱",
            "formCompany": "公司名称",
            "formImage": "上传自拍照片",
            "formSubmit": "安排演示",
            "testimonials": "感言",
            "useCases": "用例",
            "faqs": "常见问题",
            "faqQuestion1": "Validia 与哪些平台兼容？",
            "faqQuestion2": "Validia 与哪些企业产品集成？",
            "faqQuestion3": "什么是生物打印？",
            "faqQuestion4": "隐私方面如何处理？",
            "faqQuestion5": "你们提供本地解决方案吗？",
            "faqQuestion6": "Validia 会存储通话录音吗？",
            "faqQuestion7": "Validia 在根据特定组织需求进行定制方面有多灵活？",
            "faqQuestion8": "Validia 如何防范深度伪造技术（deepfakes）？",
            "faqQuestion9": "Validia 是否与我们现有的安全系统兼容？",
            "faqAnswer1": "Validia 支持 Zoom、Microsoft Teams、Slack、Google Meet 和 Webex！",
            "faqAnswer2a": "日历：Outlook 和 Google",
            "faqAnswer2bi": "人力资源系统：",
            "faqAnswer2bii": "超过 50 种平台集成",
            "faqAnswer2ci": "ATS 系统：",
            "faqAnswer2cii": "超过 25 种平台集成",
            "faqAnswer3": "Bioprint 是 Validia 独特的员工面部和声音档案。它由一张图片和一个简短的声音样本创建，并用于在视频和语音通话中进行实时身份验证。",
            "faqAnswer4a": "Validia 符合 SOC-II 类型 I 标准。类型 II 审计正在进行中。我们的 Bioprint 技术提供灵活的隐私选项：",
            "faqAnswer4b": "1. 应组织要求，我们可以在不存储面部图像或声音样本的原始数据的情况下运行。",
            "faqAnswer4c": "2. 或者，如果组织偏好，我们可以出于可观察性目的存储这些数据。",
            "faqAnswer4d": "无论哪种情况，我们只使用不可逆嵌入进行身份验证。这些嵌入无法转换回原始样本，也无法在我们的平台外部使用，从而确保最大程度的安全。",
            "faqAnswer5": "是的，我们为有特定安全需求的客户提供处于测试阶段的本地部署选项。",
            "faqAnswer6": "不，Validia 遵守严格的数据隐私标准。公司不会存储或使用任何通话录音。",
            "faqAnswer7": "Validia 具有高度可定制性。您可以在组织和用户级别设置权限，并根据需要开启或关闭功能。",
            "faqAnswer8": "KYE 在通话中实时验证身份。Jam 会破坏 AI 模型的输出，防止它们准确复制受保护的数字资产。",
            "faqAnswer9": "是的，Validia 设计上可以与大多数现有的企业安全系统集成。",
            "talkToSomeoneReal": "与真实的人交谈",
            "whyShouldYouProtect": "你为什么要保护自己?",
            "aboutUsParagraph": "在 Validia，我们是一支充满热情和前瞻性思维的团队，致力于维护数字完整性。作为深度造假预防和保护解决方案前沿的初创公司，我们将尖端人工智能与创新安全实践相结合，帮助企业、个人和平台抵御人工智能生成的内容操纵日益增长的威胁。我们多元化的团队汇集了人工智能研究、网络安全和数字取证的专业知识，所有这些都致力于构建一个更安全、更值得信赖的数字世界。我们不仅仅打击深度造假，我们还构建安全、真实的数字交互的未来。",
            "ourProducts": "我们的产品",
            "privacy": "隐私",
            "bookDemoNow": "立即预订演示",
            "bookDemo": "预订演示",
            "newsletter": "通讯",
            "stayUpToDateOnDeepfakes": "了解 Deepfakes 的最新动态",
            "enterpriseIntegrations": "企业集成",
            "strategicUseCases": "战略应用案例",
            "strategicUseCasesSubtitle": "探索 Validia 如何在人工智能时代解决企业的关键挑战。",
            "ourMission": "我们的使命",
            "ourVision": "我们的愿景",
            "ourValues": "我们的价值观",
            "ourMissionDescription": "通过先进的AI安全解决方案赋能组织，在应对不断演变的AI驱动威胁时，确保每一次数字互动中的信任与诚信。",
            "ourVisionDescription": "一个企业能够在不妥协安全、隐私或真实性的前提下，充分利用AI的力量的世界。",
            "ourValuesDescription1": "安全创新",
            "ourValuesDescription2": "所有互动中的诚信",
            "ourValuesDescription3": "主动的威胁预防",
            "ourValuesDescription4": "持续的适应",
            "recruitmentFraudPrevention": "招聘欺诈防范",
            "vendorAndThirdPartySecurity": "供应商和第三方安全",
            "mediaAndExecutiveProtection": "媒体和高管保护",
            "fraudPrevention": "欺诈防范",
            "recruitmentFraudPreventionDescription": "通过我们的了解申请人解决方案，防止AI生成的简历、面试深度伪造和欺诈性申请。",
            "vendorAndThirdPartySecurityDescription": "确保与外部合作者（如供应商、承包商和供应链伙伴）的互动安全。",
            "mediaAndExecutiveProtectionDescription": "防止企业媒体和高层高管肖像在生成性AI模型中的滥用。",
            "fraudPreventionDescription": "阻止发票篡改、中间人攻击和由AI支持的身份盗窃。",
            "latestInsights": "最新洞察",
            "readMoreOn": "阅读更多",
            "validiasBlog": "Validia的博客",
            "advancedAuthenticationCapabilities": "高级认证能力",
            "streamlinedOnboarding": "简化入职",
            "highQualityInsights": "高质量洞察",
            "advancedAuthenticationCapabilitiesDescription": "利用内部生物识别技术和先进的人工智能，Validia 以高精度认证个人身份。",
            "streamlinedOnboardingDescription": "对于每个使用案例，Validia 提供简化的入职流程，让您尽快开始使用。",
            "highQualityInsightsDescription": "每次认证都会提供独特的洞察，包括潜在攻击者的来源地、是否曾经针对过您等信息。",
            "kypDescription": "在人工智能不断进步的时代，冒充攻击势在必行。Validia 提供虚拟工作空间中的实时身份认证，确保您知道与您对话的人是谁。",
            "knowYour": "了解您的",
            "knowYour1": "员工",
            "knowYour2": "申请人",
            "knowYour3": "供应商",
            "exploreMore": "探索更多",
            "clickThroughDemo": "点击演示",
            "keyCapabilities": "主要功能",
            "requestACustomDemo": "请求定制演示",
            "secureMeetings": "安全会议",
            "secureMeetingsDescription": "通过Validia体验主动的风险减少、实时安全性以及增强的信任和合规性。利用Validia的先进身份验证技术，了解并信任您视频通话中的人员，无论是员工、招聘候选人还是第三方供应商。",
            "secureMeetingsFeature1": "与您的通话无缝集成",
            "secureMeetingsFeature2": "5到10秒的身份验证",
            "secureMeetingsFeature3": "无摩擦的安全性",
            "readMore": "查看更多",
        }
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
