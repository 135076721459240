import './BlogTeaser.css';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import client from '../config/contentful';
import { useTranslation } from 'react-i18next';

const BlogTeaser = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // FORCING a delay to see the loading state
        await new Promise(resolve => setTimeout(resolve, 500));

        const response = await client.getEntries({
          content_type: 'blogPost',
          order: '-sys.createdAt'
        });

        const maxPosts = 3;

        const formattedPosts = response.items.map(item => ({
          id: item.sys.id,
          slug: item.fields.slug,
          title: item.fields.title,
          excerpt: item.fields.excerpt,
          date: (new Date(item.fields.date ? item.fields.date : item.sys.createdAt)).toLocaleDateString(),
          readTime: item.fields.readTime || '5 min read',
        })).slice(0, maxPosts);

        setPosts(formattedPosts);
        // Small delay before removing loading state
        setTimeout(() => setLoading(false), 300);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);


  return (
    <>
        <div className={`blog-container ${!loading ? 'loaded' : ''}`}>

          {posts.length > 0 && (
            <>
              <h2>{t("latestInsights")}</h2>
              <p>{t("readMoreOn")} <Link to="/blog">{t("validiasBlog")}</Link>.</p>
              <div className="posts-grid">
                {posts.map(post => (
                  <article key={post.id} className="post-card">
                    <Link to={`/blog/${post.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <div className="post-content">
                        <h2 className="post-title">{post.title}</h2>
                        <p className="post-excerpt">{post.excerpt}</p>
                        <span className="read-more">{t("readMore")} →</span>
                      </div>
                    </Link>
                  </article>
                ))}
              </div>
            </>
          )}
        </div>
    </>
  );
};

export default BlogTeaser;
