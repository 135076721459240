import { React, useEffect } from 'react';
import NotFound from './components/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/Home';
import ThankYou from './components/ThankYou';
import BlogPage from './components/BlogList';
import BlogPost from './components/BlogPost';
import Policy from './components/PolicyComponent';
import TermsPolicy from './components/TermsComponent';
import ScrollToAnchor from './components/ScrollToAnchor';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

    function Redirect() {

        const redirectUrl = "https://calendly.com/d/cnf8-mcr-v2y/validia-demo-call-website";

        useEffect(() => {
            window.location.href = redirectUrl;
        }, []);

        return <p>Redirecting to <a href={redirectUrl}>{redirectUrl}</a>...</p>;
    }

  return (
    <Router>
      < ScrollToAnchor />
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/demo" element={<Redirect />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/thanks" element={<ThankYou />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/terms" element={<TermsPolicy />} />
          <Route path="/privacy" element={<Policy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
