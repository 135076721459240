import React from 'react';
import './Integrations.css';
import { useTranslation } from 'react-i18next';
import '../i18n'; // Import the i18n configuration

function Integrations() {
    const { t } = useTranslation();

    const integrations = [
      { name: 'Gusto', logo: 'gusto.png' },
      { name: 'BambooHR', logo: 'bamboo.png' },
      { name: 'Greenhouse', logo: 'greenhouse.png' },
      { name: 'Workday', logo: 'workdau.png' },
      { name: 'ServiceNow', logo: 'servicenow.svg' },
      { name: 'ADP', logo: 'adp.png' },
      { name: 'Deel', logo: 'deel.png' },
      { name: 'SuccessFactors', logo: 'sap.png' },
      { name: 'UKG', logo: 'ukg.png' },
      { name: 'Salesforce', logo: 'salesforce.png' },
      { name: 'Zendesk', logo: 'zendesk.png' },
      { name: 'Okta', logo: 'okta.png' },
      { name: 'Box', logo: 'box.png' },
      { name: 'OneDrive', logo: 'one.png' },
      { name: 'DropBox', logo: 'drop.png' },
      { name: 'Drive', logo: 'drive.png' },
      { name: 'Sharepoint', logo: 'sharepoint.png' },
      { name: 'Calendar', logo: 'calendar.png' },
      { name: 'Outlook', logo: 'outlook.jpeg' },
    ];

    const formatClassName = (name) =>
      name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

    return (
      <div className="integrations-container">
        <h2>{t('enterpriseIntegrations')}</h2>
        <div className="integrations-scroll">
          <div className="integration-item-set integration-item-primary-set">
              {integrations.map((integration, index) => (
                <div key={index} className="integration-item integration-item-primary">
                  <img
                    src={integration.logo}
                    alt={`${integration.name} logo`}
                    className={`${formatClassName(integration.name)}-logo integration-logo`}
                  />
                  <p>{integration.name}</p>
                </div>
            ))}
          </div>
          {/* Duplicate cards for seamless looping */}
          <div className="integration-item-set integration-item-secondary-set">
              {integrations.map((integration, index) => (
                <div key={index} className="integration-item integration-item-secondary">
                  <img
                    src={integration.logo}
                    alt={`${integration.name} logo`}
                    className={`${formatClassName(integration.name)}-logo integration-logo`}
                  />
                  <p>{integration.name}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }


export default Integrations;
