import React from 'react';
import './BelowHeaderBanner.css';
import { Trophy } from 'lucide-react';

function BelowHeaderBanner() {

    return (
        <div className="below-header-banner">
            <Trophy className="hidden-on-mobile" style={{ height: '16px', width: '16px', color: 'white', display: 'block', marginRight: '5px' }} />
            Validia Joins CrowdStrike x AWS Cybersecurity Accelerator
            <button onClick={() => window.location.href = 'https://www.linkedin.com/posts/justin-marciano32_i-am-thrilled-to-announce-that-validia-will-activity-7294801362301698048-gTAu'}>Learn more</button>
        </div>
    );
}

export default BelowHeaderBanner;
