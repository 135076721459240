import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import FlagLanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const handleNavClick = (event, sectionId) => {

    // Prevent the default behavior of the <a> tag. We keep the href on the <a> tag for accessibility + eslint warnings.
    event.preventDefault();

    // If not on the homepage, navigate to the homepage + anchor.
    if (location.pathname !== '/') {
      window.location.href = `/#${sectionId}`;
      return;
    }

    // If on the homepage, scroll to the anchor.
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false);
  };

  return (
    <header className="App-header">
      <div className="logo-container">
        <Link to="/">
          <img src="/validia_logo.png" alt="Validia Logo" className="logo" />
        </Link>
      </div>
      <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)} aria-label="Toggle menu">
        {menuOpen ? <FaTimes /> : <FaBars />}
      </button>
      <nav className={menuOpen ? 'show' : ''}>
        <ul className={`nav-list ${menuOpen ? 'mobile-menu' : ''}`}>
          <li><a href="/#product" onClick={(e) => handleNavClick(e, 'product')} style={{cursor: 'pointer'}}>{t('product')}</a></li>
          <li><a href="/#use-cases" onClick={(e) => handleNavClick(e, 'use-cases')} style={{cursor: 'pointer'}}>{t('useCases')}</a></li>
          <li><a href="/#faqs" onClick={(e) => handleNavClick(e, 'faqs')} style={{cursor: 'pointer'}}>{t('faqs')}</a></li>
          <li><a href="/#contact" onClick={(e) => handleNavClick(e, 'contact')} style={{cursor: 'pointer'}}>{t('contact')}</a></li>
          <li className="mobile-cta">
            <button className="secondary-button" onClick={() => window.location.href = 'https://app.validia.ai'}>{t('signin')}</button>
          </li>
          <li className="mobile-cta">
            <button className="primary-button" onClick={() => window.location.href = 'https://calendly.com/d/494-3xs-7yt/identifai-30-minute-meeting'}>{t('getDemo')}</button>
          </li>
          {menuOpen && <li className="mobile-language-selector"><FlagLanguageSelector /></li>}
        </ul>
      </nav>
      <div className="cta-buttons desktop-cta">
        <Button variant="outlined" disableElevation className="secondary-button" onClick={() => window.location.href = 'https://app.validia.ai'}>{t('signin')}</Button>
        <Button variant="contained" disableElevation className="primary-button" onClick={() => window.location.href = '/demo'}>{t('getDemo')}</Button>
      </div>
      <div className="desktop-language-selector">
        <FlagLanguageSelector />
      </div>
    </header>
  );
}

export default Header;
